import { service } from '@/ajax/request';

export const addGroupShare = (params: any) => (
    service({
      url: '/share-group/save-or-update',
      method: 'POST',
      params,
    })
  )
export const groupShareList = (params: any) => (
    service({
      url: '/share-group/get-page',
      method: 'POST',
      params,
    })
  )
export const groupShareDeviceList = (params: any) => (
    service({
      url: '/share-group-device/get-enable-share-page',
      method: 'POST',
      params,
    })
  )
export const groupRelationDevice = (params: any) => (
    service({
      url: '/share-group-device/save-or-update',
      method: 'POST',
      params,
    })
  )

export const getGroupRelationDevice = (groupId: any) => (
    service({
      url: `/share-group-device/get-list/${groupId}`,
      method: 'POST',
    })
  )

export const addGroupCombo = (params: any) => (
    service({
      url: `/share-group-combo/save-or-update`,
      method: 'POST',
      params
    })
  )

export const getGroupComboDeviceList = () => (
    service({
      url: `/share-group-combo/get-device-list`,
      method: 'POST',
    })
  )
export const getGroupComboListByGroupId = (params: any) => (
    service({
      url: `/share-group-combo/get-page`,
      method: 'POST',
      params
    })
  )

export const getShareComboUserList = (params: any) => (
    service({
      url: `/share-user-combo/get-page-by-all-user`,
      method: 'POST',
      params
    })
  )

export const addShareComboUserCount = (params: any) => (
    service({
      url: `/share-user-combo/add-count-manu`,
      method: 'POST',
      params
    })
  )
export const getShareComboBuyUser = (params: any) => (
    service({
      url: `/share-user-combo/get-page-by-user`,
      method: 'POST',
      params
    })
  )

export const getCustomShareCombo = (params: any) => (
    service({
      url: `/share-user-combo/get-page`,
      method: 'POST',
      params
    })
  )

export const getCustomShareComboExpense = (params: any) => (
    service({
      url: `/share-user-combo-record/get-page`,
      method: 'POST',
      params
    })
  )

export const getUserShareCards = (params: any) => (
    service({
      url: `/share-user-combo/get-user-cards`,
      method: 'POST',
      params
    })
  )