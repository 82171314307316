
import { Component, Vue, Prop } from 'vue-property-decorator'

import { allStoreList } from "@/api/store";

import {
    debounce
} from "lodash"
@Component
export default class GetStoreByRemote extends Vue {
    storeSelectLoading = false;
    allStoreList = [];
    
    getStoreRemote = debounce(function (str) {
        this.storeSelectLoading = true;
        allStoreList({storeName: str,pageNum: 1,pageSize: 500}).then((res) => {
          this.storeSelectLoading = false;
          this.allStoreList = res.data;
        }).catch((err) => {}).finally(() => {
            this.storeSelectLoading = false;
        });
    })

    getStoreList(str) {
        if (typeof str == "object") {
          str = "";
        }
        this.getStoreRemote(str)
      }
}







