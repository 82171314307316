
import { Component, Vue, Mixins, Prop, Watch, Emit } from "vue-property-decorator";

import dayjs from "dayjs";

@Component
export default class BasicCardList extends Vue {
    @Prop({ default: () => [] }) cardList: any;

    get userInfo(){
        return this.cardList?.[0] || {};
    }
}
